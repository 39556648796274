<template>
    <footer class="main-footer">
        <div class="float-right d-none d-sm-inline">
            Apply any time you need
        </div>
        <strong>Copyright &copy; 2024 <a href="https://lupab.co.tz" style="color: #ae275f;">LUPAB FINANCE COMPANY LTD</a>.</strong> All rights reserved.
    </footer>
</template>

<script>
    export default {
        name: 'FootComponent',
        components: {
            
        }
    }
</script>