<template>
<div class="card card-solid">
    <div class="card-header">
        <h1 class="card-title">Malalamiko/Maoni/Maulizo</h1>
    </div>
    <div class="card-body pb-0">
        <p style="text-align: justify;">Tafadhali usisite kututumia ujumbe kuhusu huduma zetu, kwasababu ujumbe wako utatusaidia kuboresha huduma zetu.</p>
        <div class="row">
            <div class="col-12">
                <form action="#" method="post" @submit.prevent="submitApplication()" id="submitform">
                    <div class="row">
                        <div class="col-12">
                            <label for="category">Chagua aina ya ujumbe<i style="color:red;">*</i></label>
                            <select class="form-control" name="category" v-model="category" required>
                                <option value=""></option>
                                <option value="Claim">Malalamiko</option>
                                <option value="Advice">Maoni</option>
                                <option value="Inquiry">Maulizo</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <label for="description">Maelezo <i style="color:red;">*</i></label>
                            <textarea name="description"  class="form-control" rows="3" cols="4" v-model="description" required></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label for="next_of_kin_phone_number">Namba yako ya simu</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <button type="button" class="btn btn-primary"><span class="fa fa-phone"></span></button>
                                </div>
                                <input type="text" class="form-control rounded-0" name="phone_number" pattern="^[0]{1}[1-9]{1}[0-9]{8}$" v-model="phone_number"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-12" style="text-align: right;">
                            <div class="btn-group" role="group" aria-label="Action">
                                <router-link to="/home" class="btn btn-danger btn-sm"> Cancel <i class="fa fa-times-circle"></i> </router-link>
                                <button type="reset" class="btn btn-secondary btn-sm">Clear <i class="fa fa-trash"></i></button>
                                <button type="submit" class="btn btn-primary btn-sm">Submit <i class="fa fa-arrow-circle-right"></i></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="card-footer">

    </div>
</div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "ContactComponent",
        data(){
            return {
                category:'',
                description:'',
                phone_number:''
            }
        },
        methods:{
            async submitApplication(){
                var request_data = {
                    'category': this.category,
                    'description':this.description,
                    'phone_number':this.phone_number
                };
                this.$swal({
                    text: 'Tafadhali subiri ...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showCancelButton: false,
                    showConfirmButton: false,
                    onOpen: ()=>{
                                this.$swal.showLoading();
                            } 
                    });
                    try{
                        //axios.defaults.withCredentials = true;
                        //await axios.get("http://localhost/microfinance/public/sanctum/csrf-cookie");
                        var resp = await axios.post("https://mis.lupab.co.tz/contact_us",request_data);
                        if(resp.status == 200){
                            if(resp.data.code == 200){
                                document.getElementById("submitform").reset();
                                this.$swal({
                                    icon:"success",
                                    text:resp.data.message,
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                });
                            }else{
                                this.$swal({
                                    icon:"error",
                                    text:resp.data.message
                                });
                            }
                        }else{
                            this.$swal({
                                    icon:"error",
                                    text:"Request failed"
                                });
                        }
                    }catch(error){
                        this.$swal({
                            title:"Status",
                            icon:"error",
                            text:"Exception occured"
                        });
                    }
            }     
        }
    }
</script>