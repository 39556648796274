<template>
<div class="card">
    <div class="row">
        <div class="col-12">
            <img src="/images/apply-for-loans-banner.jpg" alt="Noimage" width="100%"/>
        </div>
    </div>
    <div class="card-body pb-0">
    <div class="row">
    <div class="col-12 col-sm-12 col-md-12 d-flex align-items-stretch flex-column">
    <div class="card bg-light d-flex flex-fill">
    <div class="card-header text-muted border-bottom-0">
    About Us
    </div>
    <div class="card-body pt-0">
        <div class="row">
            <div class="col-12">
                <h2 class="lead"><b>LUPAB FINANCE COMPANY LTD</b></h2>
                <p class="text-muted text-sm"><b>About: </b> LUPAB FINANCE is a reputable lending company dedicated to providing a wide range of financial solutions tailored to your unique needs. We specialize in personal loans, business loans, and more, offering quick, flexible, and dependable support to help you achieve your financial goals. Partner with us for transparent, customer-focused lending services you can trust
                </p>
                    <ul class="ml-4 mb-0 fa-ul text-muted">
                    <li class="small"><span class="fa-li"><i class="fas fa-lg fa-building"></i></span> Address: Dodoma, Dodoma Town, Street Iyumbu</li>
                    <li class="small"><span class="fa-li"><i class="fas fa-lg fa-phone"></i></span> Phone #: 0743843428</li>
                    <li class="small"><span class="fa-li"><i class="fas fa-lg fa-envelope"></i></span> Email: info@lupab.co.tz</li>
                </ul>
            </div>
            
        </div>
    </div>
   
    </div>
    </div>
  
    </div>
    </div>
</div>
</template>

<script>
    export default {
    name: "AboutUs",
        props: {
            msg: String
        }
    }
</script>