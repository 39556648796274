<template>
   <div class="row">
        <div class="col-12">
            <img src="/images/apply-for-loans-banner.jpg" alt="Noimage" width="100%"/>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 mt-3">
            <div class="card">
              <div class="card-header">
                <h2 class="m-0 card-title" style="text-align:left"> Pata Mkopo <small>Chapu Chapu</small></h2>
              </div>
              <div class="card-body">   
                  <p class="card-text">Mkopo wa Ada</p>
                  <div class="dropdown-divider"></div>
                  <p class="card-text">Mkopo wa Biashara</p>
                  <div class="dropdown-divider"></div>
                  <p class="card-text">Mkopo Binafsi</p>
                  <div class="dropdown-divider"></div>
                  <p class="card-text">Mkopo wa Vikundi</p>
                  <div class="row">
                  <div class="col-12" style="text-align: right;">
                    <router-link to="/loan-application" class="btn btn-primary" style="background-color: #ae275f;border-color: #ae275f;" >Omba Mkopo <i class="fa fa-arrow-circle-right"></i></router-link>
                  </div>
                </div>
                  
              </div>
                
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
          <div class="card">
            <div class="card-header">
              <h2 class="m-0 card-title" style="text-align:left"> karibu <small>tukuhudumie</small></h2>
            </div>
              <div class="card-body">
                <img v-bind:src="`${publicPath}images/Bunner.jpeg`" />
              </div>
            </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "HomePage",
  data () {
    return {
      publicPath: process.env.BASE_URL,
      mode:process.env.NODE_ENV
    }
  }
}
</script>