<template>
  <head-component></head-component>
    <div class="content-wrapper">
      <div class="content-header">
        <div class="container">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1 class="m-0" style="color:#ae275f;"> LUPAB <small>FINANCE </small></h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#" style="color: #ae275f;">Home</a></li>
            </ol>
          </div>
        </div>
        </div>
      </div>
      <div class="content" id="content">
        <div class="container fill-height">
            <router-view v-slot="{ Component }">
                  <transition name="scale" mode="out-in">
                    <component :is="Component" />
                  </transition>
            </router-view>
            <!-- <router-view>
                
            </router-view> -->
        </div>
      </div>
  </div>
  <foot-component></foot-component>
</template>

<script>
import HeadComponent from './components/HeadComponent.vue'
import FootComponent from  './components/FootComponent.vue'

  export default {
    name: 'App',
    components: {
      HeadComponent,
      FootComponent
    }
  }
</script>
<style>
  .scale-enter-active,
  .scale-leave-active {
    transition: all 0.5s ease;
  }

  .scale-enter-from,
  .scale-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
</style>