import { createApp } from 'vue';
import { createRouter,createWebHistory } from 'vue-router';
import App from './App.vue';
import HomePage from './components/HomePage.vue';
import ContactComponent from  './components/ContactComponent.vue';
import LoanApplication from  './components/LoanApplication.vue';
import AboutUs from './components/AboutUs.vue';
import VueSweetalert2 from 'vue-sweetalert2';
import { component as VueNumber } from '@coders-tm/vue-number-format';
import  VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: HomePage },
        { path: '/home', component: HomePage },
        { path: '/contact', component: ContactComponent },
        { path: '/ubout-us', component: AboutUs },
        { path: '/loan-application', component: LoanApplication }
    ]
});
const app = createApp(App);
app.use(router);
app.use(VueSweetalert2);
app.component('VueDatePicker', VueDatePicker);
app.component('VueNumber', VueNumber);
app.mount('#app');
